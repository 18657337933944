import { graphql } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'semantic-ui-react';
import Layout from '../components/Layout';

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
`;

export default ({ data }) => {
    const { siteMetadata } = data.site;
    const siteTitle = siteMetadata.title;
    const siteDescription = siteMetadata.description;
    return (
        <Layout>
            <Helmet
                htmlAttributes={{ lang: 'en' }}
                meta={[{ name: 'description', content: siteDescription }]}
                title={siteTitle + ' | Not Found!'}
            />
            <Container text>
                <h1>Not Found</h1>
                <p>Hmm... doesn't look like we have what you're after.</p>
            </Container>
        </Layout>
    );
};
